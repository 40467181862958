<template>
<div id="page">
	<div id="section_wrapper" class="section_wrapper-work fl-co-t-r" vertical>
		<div class="w_left ab">
			<div class="w_topimg bi wh" :style="`background-image: url(${banner})`"></div>
		</div>
		<section ref="work-section" class="fl-co-t">
			<router-link
				tag="div"
				@mousemove.native="updateCursorEl([$event,{y:false}])" @mouseleave.native="updateCursorEl"
				:to="{name: 'works', query:{s:work.slug}}"
				class="w_back upper fl-c-l"
				exact>
					<div class="w_back-wrapper fl-c-l pe">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 23.8" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.5 11.9l6.8 6.7v-5.7h10v-2h-10V5.1z"/></svg>
						My work
					</div>
			</router-link>
			<h1 class="w_title">{{ work.name }}</h1>
			<div class="w_content fl-co-l">
				<component v-if="work.links_title" class="wi_links_title" :is="'WorkParagraph'" v-bind:content="work.links_title"></component>
				<div
				class="w_links-wrapper fl-c-l fl-w"
				:class="{w_multiple_links: multipleLinks}">
					<a v-for="(link, i) in work.links"
						:key="'link'+i"
						:href="link.url"
						target="_blank"
						class="w_link upper fl-c link_ul"
						@mousemove="updateCursorEl([$event,{x:false}])" @mouseleave="updateCursorEl">
						<div class="fl-c pe">
							<span v-html="link.text"></span>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.7 14.7" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.7 0h-9.6l4.1 4.1-8.6 8.6H0v2h8.4l9.2-9.2 4.1 4z"/></svg>
						</div>
					</a>
					<div  v-for="(pop, i) in work.popup"
						:key="'pop'+i"
						class="w_link upper fl-c link_ul"
						@click="openPopup(pop)"
						@mousemove="updateCursorEl([$event,{x:false}])" @mouseleave="updateCursorEl">
						<div class="fl-c pe">
							<span v-html="pop.text"></span>
							<svg viewBox="0 0 23.8 23.8" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.2 21h-17V6.8h17V21zM2.7 19.5h14V8.3h-14v11.2z"/><path d="M22.5 16.1H21v-12H9V2.6h13.5z"/></svg>
						</div>
					</div>
				</div>
				<div class="w_meta fl-c-l upper">
					<span class="w_brief">{{ work.brief }}</span>
					<span class="w_date">{{ work.date }}</span>
				</div>
				<component :is="'WorkParagraph'" v-bind:content="description"></component>
			</div>
			<div class="w_technologies fl-co-l">
				<div class="w_meta upper">Technologies & Tools</div>
				<div class="w_tech_wrapper fl-c-l fl-w">
					<div class="tech fl-co-c-t" v-for="(tech, i) in work.technology" :key="i">
						<img class="w1" Qsvg :src="svgUrl(tech)">
						<span>{{ getTechnology(tech) }}</span>
					</div>
				</div>
			</div>
			<div class="fl-c-l" v-if="sections.length">
				<div class="line-h"></div>
			</div>
		</section>
		<section v-for="(section, i) in sections" :key="i" class="w_description-section">
			<div class="work-content_wrapper">
				<component
				v-for="(part, ii) in section"
				:key="ii + '_' + i"
				:is="getComponentName(part.type)"
				v-bind:content="part.content">
				</component>
			</div>
		</section>
	</div>
</div>
</template>

<script>



// import gsap from "gsap"
import { mapActions, mapGetters } from 'vuex'
import WorkImage from '@/components/work/elements/WorkImage.vue'
import WorkParagraph from '@/components/work/elements/WorkParagraph.vue'
import WorkHeading from '@/components/work/elements/WorkHeading.vue'

export default {
	name: "work",
	components: {
		WorkImage,
		WorkParagraph,
		WorkHeading
	},
    props: {
        slug : { type: null }
	},
	data: () => ({
		loading: true,
		error: null,
		content: null,
	}),
	mounted() {
		this.getContent()
		this.setSections([this.$refs, this.$route.name])
		if(this.work.light != undefined) this.updateLight(this.work.light)
		if(this.work.scrollbarColor) {
			this.updateScrollbarColor(this.work.scrollbarColor)
		} else {
			this.updateScrollbarColor(null)
		}
	},
	destroyed() {
		this.updateScrollbarColor(null)
	},
	computed: {
		...mapGetters ("WorksStore", [
			'getWork',
			'getTechnology'
		]),
		...mapGetters ("GeneralStore", [
			'getLight'
		]),
		work() {
			return this.getWork(this.slug)[0]
		},
		banner() {
			return `/assets/img/banner/${this.work.id}.jpg`
		},
		description() { return this.content ? this.content.description : '' },
		sections() {
			if(!this.content) return []
			return this.content.sections ? this.content.sections : []
		},
		multipleLinks() {
			return (this.work.links && this.work.links.length > 2) || (this.work.popup && this.work.popup.length > 2)
		}
	},
	methods: {
		...mapActions ("GeneralStore", [
			'setSections',
			'updateLight',
			'updateCursor',
			'updateCursorEl',
			'updatePopup',
			'updateScrollbarColor',
			'updatePopupActive'
		]),
		svgUrl(id) {
			return this.getLight ? `/assets/img/technologies/black/${id}.svg` : `/assets/img/technologies/white/${id}.svg`
		},
        brandingHover(v) {
            if(this.$route.name == 'home') return
            this.updateCursor(v)
		},
		async getContent() {
			this.content = await this.axios(`/pages/${this.work.id}.json`)
		},
		openPopup(pop) {
			this.updatePopup({
				component : pop.component,
				payload   : pop.payload
			})
			this.updatePopupActive(true)
		},
	}

}
</script>